<template>
  <NavBar />
  <div class="row" style="height: 8vh;"></div>
  <div class="signup-header">
    <div class="row" style="height: 8vh;"></div>
    <div class="row" style="height: 76vh">
      <div class="col-1"></div>
      <div class="col-4" style="background-color: #EDF2EF; border-radius: 15px 0 0 15px">
        <div style="height: 4vh;"></div>
        <img src="../assets/storyset/Signup-Learning.gif" style="width: 30vw; margin-left: 2.5vw;">
      </div>
      <div class="col-3" style="background-color: #EDF2EF;">
        <p class="text-h2" style="font-family: Montserrat; font-weight: 700; margin-top: 4vh; margin-bottom: 1vh; margin-right: 5vw">zerudite</p>
        <p class="text-h4 lato">prepares <i>you</i> for what's next.</p>
        <p class="text-h4 lato" style="margin-right: 5vw;">Get started today and earn a <i>bonus</i> <b>200 credits</b>.</p>

      </div>
      <div class="col-3 signup-dialog">
        <p class="text-h4 text-white" style="margin-left: 2.5vw; margin-top: 4vh; font-family: Lato">Get Started</p>
        <q-scroll-area style="height: 55vh; margin-left: 2.5vw;" dark>
          <q-form style="margin-right: 2.5vw;" @submit="onSubmit">
            <p class="lato text-white" style="margin-bottom: 0px; font-size: 14px">Full Name</p>
            <q-input color="white" bg-color="white" outlined v-model="nameText" label="Enter your name" label-color="grey-7" style="font-family: Lato;" :rules="[val => val.length > 4 || 'Name must be longer than 4 characters']" />

            <p class="lato text-white" style="margin-bottom: 0px; font-size: 14px">Username (choose wisely, it's permanent)</p>
            <q-input color="white" bg-color="white" outlined v-model="usernameText" label="Enter a username" label-color="grey-7" style="font-family: Lato;" :rules="[val => val.length > 2 || 'Username must be longer than 2 characters', checkUserName]" />

            <p class="lato text-white" style="margin-bottom: 0px; font-size: 14px; margin-top: 1vh;">Email</p>
            <q-input color="white" bg-color="white"  outlined v-model="emailText" label="Enter your email" label-color="grey-7" style="font-family: Lato;" type="email" :rules="[val => val.length > 0 || 'Please enter your email address', emailValidator, checkEmail]"/>

            <p class="lato text-white" style="margin-bottom: 0px; font-size: 14px; margin-top: 1vh;">Password</p>
            <q-input color="white" bg-color="white" outlined v-model="passwordText" label="Enter your password" label-color="grey-7" style="font-family: Lato;" type="password" :rules="[val => val.length > 0 || 'Please enter your password', passwordStrengthValidator_LowerLetter, passwordStrengthValidator_UpperLetter, passwordStrengthValidator_Number, passwordStrengthValidator_Symbol, val => val.length > 8 || 'Password must be longer than 8 characters!']"/>

            <p class="lato text-white" style="margin-bottom: 0px; font-size: 14px; margin-top: 1vh;">Confirm Password</p>
            <q-input color="white" bg-color="white" outlined v-model="confirmPasswordText" label="Confirm your password" label-color="grey-7" style="font-family: Lato;" type="password" :rules="[passwordValidator]"/>

            <q-btn no-caps push label="Get Started" label-color="grey-7" style="background-color: #F8E9A1; border-radius: 20px; width: 10vw; white-space: nowrap; color: black; font-family: Montserrat; font-weight: 600; margin-top: 2vh; margin-bottom: 2vh;" type="submit" @click="onSubmit()" />
          </q-form>
        </q-scroll-area>
      </div>
      <div class="col-1"></div>
    </div>
  </div>
</template>

<script>
import  NavBar from "../components/NavBar.vue"
import {ref} from 'vue'
import { getCurrentInstance } from 'vue'
import firebase from 'firebase'
import { DeviceUUID } from 'device-uuid'
import { Dialog } from 'quasar'

export default {
  setup () {
    const internalInstance = getCurrentInstance()
    var nameText = ref("")
    var emailText = ref("")
    var passwordText = ref("")
    var confirmPasswordText = ref("")
    var usernameText = ref("")
    var banAlert = ref(false)

    return {
      nameText,
      emailText,
      passwordText,
      confirmPasswordText,
      banAlert,
      internalInstance,
      usernameText
    }
  },
  methods: {
    emailValidator () {
      const emailPattern = /^(?=[a-zA-Z0-9@._%+-]{6,254}$)[a-zA-Z0-9._%+-]{1,64}@(?:[a-zA-Z0-9-]{1,63}\.){1,8}[a-zA-Z]{2,63}$/
      return emailPattern.test(this.emailText) || 'Please enter a valid email address'
    },
    passwordValidator() {
      return this.confirmPasswordText === this.passwordText || 'Passwords must match!'
    },
    passwordStrengthValidator_Number() {
      return /\d/.test(this.passwordText) || 'Weak Password! Consider adding a number.'
    },
    passwordStrengthValidator_LowerLetter() {
      return this.passwordText.toUpperCase() !== this.passwordText || 'Weak Password! Consider adding lowercase letters.'
    },
    passwordStrengthValidator_UpperLetter() {
      return this.passwordText.toLowerCase() !== this.passwordText || 'Weak Password! Consider adding uppercase letters.'
    },
    passwordStrengthValidator_Symbol() {
      return /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/.test(this.passwordText) || 'Weak Password! Consider adding a symbol.'
    },
    async checkEmail() {
      try {
        let emailCheck = await firebase.auth().fetchSignInMethodsForEmail(this.emailText)
        return emailCheck.length === 0 || 'This email is already in use!'
      } catch (e) {
        // this comment exists only to make ESLint work. fuck ESLint
      }
    },
    async checkUserName() {
      let url = this.internalInstance.appContext.config.globalProperties.$backendURL
      let response = await fetch(url + '/isUsernameTaken',{
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username: this.usernameText
        })
      })
      let result = await response.json()
      console.log(result)
      return !result.isUsernameTaken || 'This username is already in use!'
    },
    async onSubmit () {
      let url = this.internalInstance.appContext.config.globalProperties.$backendURL

      let response = await fetch(url + '/isDeviceIDTaken', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          deviceID: new DeviceUUID().get()
        })
      })
      let result = await response.json()

      if (!result.isDeviceIDUsed) {
        await firebase.auth().createUserWithEmailAndPassword(this.emailText, this.passwordText)
        let IDToken = await firebase.auth().currentUser.getIdToken(true)
        const user = firebase.auth().currentUser
        await fetch(url + '/createUser', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            email: user.email,
            name: this.nameText,
            deviceID: new DeviceUUID().get(),
            token: IDToken,
            username: this.usernameText,
          })
        })
        await user.sendEmailVerification()
        Dialog.create({
          title: 'Email Verification',
          message: 'Welcome to Zerudite! Before you can complete your registration, we would like you to verify your email. Please check your email for a verification email. Click on the link, then verify your email. Upon doing so, you will be able to log in.',
          persistent: true,
        }).onOk(async ()  => {
          await firebase.auth().signOut()
          await this.$router.replace('/login')
        })
      }
      else {
        Dialog.create({
          title: 'Violation of TOS | Multiple Accounts',
          message: 'Zerudite TOS strictly prohibits the usage of multiple accounts. Please log in with your current account.',
          persistent: true
        }).onOk(async () => {
          await this.$router.replace('/homepage')
        }).onCancel(() => {
          // console.log('Cancel')
        }).onDismiss(() => {
          // console.log('I am triggered on both OK and Cancel')
        })
        return
      }
    }

  },
  async mounted() {
    await firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        await this.$router.push('/profile')
      }
    })
  },
  name: "Signup",
  components: {
    NavBar
  }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Gentium+Basic:ital,wght@0,400;0,700;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
@font-face {
  font-family: "Farray";
  src: url("../assets/fonts/FARRAY.otf");
}

.signup-header {
  background-image: url("../assets/loginbkg.png");
  background-size: cover;
  height: 92vh;
}

.signup-image {
  background-image: url("../assets/tutoring-image-loginpage.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 20px 0 0 20px;
}

.signup-dialog {
  background-color: black;
  border-radius: 0 20px 20px 0;
}

.gentium {
  font-family: Gentium Basic;
}

.lato {
  font-family: Lato;
}



</style>
<style>

.q-field__messages {
  color: teal !important;
}

.text-negative {
  color: teal !important;
}
</style>
